<template>
  <b-card>
    <div
      v-if="dataLoad"
      class=" text-center my-2"
    >
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </div>
    <span v-else>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Name Arabic"
            label-for="name-ar"
          >
            <b-form-input
              id="name-ar"
              v-model="certificateData.name_ar"
              trim
              placeholder="Name Arabic"
              :disabled="$route.params.id ? true : false"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Name English"
            label-for="name-en"
          >
            <b-form-input
              id="name-en"
              v-model="certificateData.name_en"
              trim
              placeholder="Name English"
              :disabled="$route.params.id ? true : false"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Description Arabic"
            label-for="descriptionAr"
          >
            <b-form-input
              id="descriptionAr"
              v-model="certificateData.description_ar"
              trim
              placeholder="Description Arabic"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">

          <b-form-group
            label="Description English"
            label-for="descriptionEn"
          >
            <b-form-input
              id="descriptionEn"
              v-model="certificateData.description_en"
              trim
              placeholder="Description English"
            />

          </b-form-group>
        </b-col>
        <b-col md="6">

          <b-form-group
            label="Process Type"
            label-for="process_type"
          >
            <b-form-select
              id="process_type"
              v-model="certificateData.process_type"
              :options="optionsProcessType"
              trim
              placeholder="Process Type"
            />

          </b-form-group>
        </b-col>
        <b-col md="6">

          <b-form-group
            label="Accountant Email"
            label-for="accountant-email"
          >
            <b-form-input
              id="name-en"
              v-model="certificateData.accountant_email"
              trim
              type="email"
              placeholder="Accountant Email"
            />

          </b-form-group>
        </b-col>
        <b-col md="6">

          <b-form-group
            label="Proccess Time Days"
            label-for="proccess_time_days"
          >
            <b-form-input
              id="proccess_time_days"
              v-model="certificateData.proccess_time_days"
              trim
              type="number"
              placeholder="Proccess Time Days"
            />

          </b-form-group>
        </b-col>
        <b-col md="6">

          <b-form-group
            label="Load"
            label-for="load"
          >
            <b-form-input
              id="load"
              v-model="certificateData.load"
              trim
              type="number"
              placeholder="Load"
            />

          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Expiration Duration Months"
            label-for="expiration_duration_months"
          >
            <b-form-input
              id="expiration_duration_months"
              v-model="certificateData.expiratino_duration_months"
              trim
              type="number"
              placeholder="Expiration Duration Months"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">

          <!-- <b-form-group
            label="Product Category"
            label-for="Product Category "
          >
            <b-form-select
              id="product_category"
              v-model="certificateData.product_category_ids"
              :options="optionsProduct"
              placeholder="Add Product"
              multiple
            />

          </b-form-group> -->
        </b-col>
        <b-col md="6">

          <b-form-group
            label="FAQs"
            label-for="file-faqs"
          >
            <div class="file-input-wrapper">
              <b-form-file
                id="file-faqs"
                multiple
                @change="handleFileUpload($event, 'faqs')"
              />
              <div class="file-chips">
                <b-badge
                  v-for="(file, index) in certificateData.faqs"
                  :key="index"
                  v-b-tooltip.hover.right
                  class="mr-2 d-flex align-items-center position-relative"
                  variant="info"
                  :title="file.name"
                  style="height: 28px;"
                >
                  <span class="file-name py-2">{{
                    truncateFileName(file.name)
                  }}</span>
                  <button
                    style="width: 20px; height: 20px; border-radius: 50%; display: flex; align-items: center; justify-content: center; margin-left: 20px"
                    @click="removeFile('faqs', index)"
                  >
                    &times;
                  </button>
                </b-badge>
              </div>
            </div>
            <div
              v-if="showFAQsFile.length > 0"
              class="py-1 d-flex flex-column"
            >
              <div
                v-for="(file, index) in showFAQsFile"
                :key="index"
                class="pt-1"
              >
                <a
                  :href="file.url"
                  target="_blank"
                  class="mr-5"
                >
                  {{ index + 1 }} - {{ truncateFileUrl(file.url) }}
                </a>
                <feather-icon
                  icon="XIcon"
                  class="cursor-pointer text-danger"
                  @click="deleteFile(file.id)"
                />
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">

          <b-form-group
            label="Standard"
            label-for="file-standard"
          >
            <div class="file-input-wrapper">
              <b-form-file
                id="file-standard"
                multiple
                @change="handleFileUpload($event, 'standard')"
              />
              <div class="file-chips">
                <b-badge
                  v-for="(file, index) in certificateData.standard"
                  :key="index"
                  v-b-tooltip.hover.right
                  class="badge-item mr-2 d-flex align-items-center"
                  style="height: 28px;"
                  variant="info"
                  :title="file.name"
                >
                  <span class="file-name py-2">{{
                    truncateFileName(file.name)
                  }}</span>
                  <button
                    style="width: 20px; height: 20px; border-radius: 50%; display: flex; align-items: center; justify-content: center; margin-left: 20px"
                    @click="removeFile('standard', index)"
                  >
                    &times;
                  </button>
                </b-badge>
              </div>
            </div>
            <div
              v-if="showStanderFile.length > 0"
              class="py-1 d-flex flex-column"
            >
              <div
                v-for="(file, index) in showStanderFile"
                :key="index"
                class="pt-1"
              >
                <a
                  :href="file.url"
                  target="_blank"
                  class="mr-5"
                >
                  {{ index + 1 }} - {{ truncateFileUrl(file.url) }}
                </a>
                <feather-icon
                  icon="XIcon"
                  class="cursor-pointer text-danger"
                  @click="deleteFile(file.id)"
                />
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="FAQ Text EN"
            label-for="faq_text_en"
          >
            <b-form-input
              id="faq_text_en"
              v-model="certificateData.faq_text_en"
              trim
              type="text"
              placeholder="FAQ Text EN"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="FAQ Text AR"
            label-for="faq_text_ar"
          >
            <b-form-input
              id="faq_text_ar"
              v-model="certificateData.faq_text_ar"
              trim
              type="text"
              placeholder="FAQ Text AR"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">

          <b-form-group
            label="price"
            label-for="price"
          >
            <b-form-input
              id="price"
              v-model="certificateData.price"
              trim
              type="number"
              placeholder="price"
            />

          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="require_approve"
            label-for="require_approve"
          >
            <b-form-select
              id="require_approve"
              v-model="certificateData.require_approve"
              :options="optionRequireApprove"
              trim
              placeholder="require_approve"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">

          <b-form-group
            label="Need Dataentry"
            label-for="Need Dataentry"
          >
            <b-form-select
              id="need_dataentry"
              v-model="certificateData.need_dataentry"
              :options="optionNeedDataentry"
              trim
              placeholder="Need Dataentry"
            />

          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Accountant Avg Time In Hours"
            label-for="Accountant Avg Time In Hours"
          >
            <b-form-input
              id="accountant_avg_time_in_hours"
              v-model="certificateData.accountant_avg_time_in_hours"
              trim
              type="number"
              placeholder="Accountant Avg Time In Hours"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">

          <b-form-group
            label="First Reviewer Avg Time In Hours"
            label-for="First Reviewer Avg Time In Hours"
          >
            <b-form-input
              id="first_reviewer_avg_time_in_hours"
              v-model="certificateData.first_reviewer_avg_time_in_hours"
              trim
              type="number"
              placeholder="First Reviewer Avg Time In Hours"
            />

          </b-form-group>
        </b-col>

        <b-col md="6">

          <b-form-group
            label="Second Reviewer Avg Time In Hours"
            label-for="Second Reviewer Avg Time In Hours"
          >
            <b-form-input
              id="second_reviewer_avg_time_in_hours"
              v-model="certificateData.second_reviewer_avg_time_in_hours"
              trim
              type="number"
              placeholder="Second Reviewer Avg Time In Hours"
            />

          </b-form-group>
        </b-col>

        <b-col md="6">

          <b-form-group
            label="Data Entry Avg Time In Hours"
            label-for="Data Entry Avg Time In Hours"
          >
            <b-form-input
              id="data_entry_avg_time_in_hours"
              v-model="certificateData.data_entry_avg_time_in_hours"
              trim
              type="number"
              placeholder="Data Entry Avg Time In Hours"
            />

          </b-form-group>
        </b-col>

      </b-row>

      <b-row v-if="!addReport && $route.params.id">
        <b-col md="12">
          <div class="text-center d-flex align-items-center justify-content-center">
            <b-btn
              class="text-center d-flex align-items-center justify-content-center px-2 py-1"
              @click="addReport = !addReport"
            >
              <feather-icon
                icon="PlusCircleIcon"
                size="20"
                class="mr-1"
              />Add Report</b-btn>

          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="addReport"
        class="bg-light py-2 px-2 rounded"
      >
        <b-col md="12">
          <div class="text-center d-flex align-items-center justify-content-end">
            <feather-icon
              icon="XIcon"
              size="20"
              class="mr-1 cursor-pointer"
              color="red"
              @click="addReport = !addReport"
            />

          </div>
        </b-col>

        <b-row
          v-for="(report, index) in optionsReports"
          :key="index"
          class="w-100"
        >
          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              :name="`Report ${index + 1}`"
              rules="required"
            >
              <b-form-group
                :label="`Name Report ${index + 1}`"
                :label-for="`name-report-${index}`"
              >
                <b-form-input
                  :id="`name-report-${index}`"
                  v-model="report.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Enter report name"
                  disabled
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col

            md="6"
          >
            <b-form-group
              :label="`Attach File for Report ${index + 1}`"
              :label-for="`file-report-${index}`"
            >
              <b-form-file
                :id="`file-report-${index}`"
                v-model="report.file"
                @input="onFileSelected($event, index)"
              />
              <div
                v-if="typeof report.file === 'string'"
                class="text-success"
              >
                <a :href="report.file">{{ report.file }}</a>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            @click="editCertificate"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            @click="addCertificate"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            /> Loading...
          </b-button>
        </b-col>
      </b-row>
    </span>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import VSelect from 'vue-select'
import decryptData from '@/store/auth/decryptData'

export default {
  // components: {
  //   'v-select': VSelect,
  // },
  data() {
    return {
      required,
      errors: {},
      loader: false,
      addReport: false,
      dataLoad: false,
      optionsProduct: [],
      optionsReports: [],
      optionRequireApprove: [
        { value: 0, text: 'false' },
        { value: 1, text: 'true' },
      ],
      optionNeedDataentry: [
        { value: 0, text: 'false' },
        { value: 1, text: 'true' },
      ],
      optionsProcessType: [
        { value: 'audit', text: 'audit' },
        { value: 'inspection', text: 'inspection' },
        { value: 'null', text: 'study' },
      ],
      showStanderFile: [],
      showFAQsFile: [],
    }
  },
  setup() {
    const certificateData = ref({
      faqs: [],
      standard: [],
    })
    return {
      certificateData,
    }
  },
  mounted() {
    this.showCertificate()
    this.getProductCategory()
  },
  methods: {
    getValidationState(validationContext) {
      return validationContext.errors[0] ? false : null
    },
    handleFileUpload(event, fieldName) {
      const { files } = event.target
      this.certificateData[fieldName] = [
        ...this.certificateData[fieldName],
        ...Array.from(files),
      ]
    },
    removeFile(fieldName, index) {
      this.certificateData[fieldName].splice(index, 1)
    },
    deleteFile(FileId) {
      this.dataLoad = true
      axios.delete(`certificate/${this.$route.params.id}/attachments/${FileId}`).then(res => {
        if (res.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted File Successfully',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        }
      }).finally(() => {
        this.dataLoad = false
        this.showCertificate()
      })
    },
    truncateFileName(fileName) {
      const words = fileName.split(' ')
      if (words.length > 3) {
        return `${words.slice(0, 3).join(' ')}...`
      }
      return fileName
    },
    truncateFileUrl(fileName) {
      const words = fileName.split('/')
      const lastWord = words[words.length - 1]
      // console.log(lastWord)
      return lastWord
    },
    getProductCategory() {
      axios.get('product-categories').then(res => {
        const product = decryptData(res.data.payload).data.productCategories
        // console.log(product)
        this.optionsProduct = product.map(item => ({
          value: item.id,
          text: item.name,
        }))
      })
    },

    getReportName() {
      axios.get('report').then(res => {
        this.optionsReports = decryptData(res.data.payload).data.reports.map(item => ({
          name: item.name,
          file: null,
        }))
        // console.log(this.optionsReports)
      })
    },
    showCertificate() {
      if (this.$route.params.id) {
        this.dataLoad = true
        axios
          .get(
            `certificate/${this.$route.params.MCR_id}/${this.$route.params.id}`,
          )
          .then(res => {
            this.certificateData = decryptData(res.data.payload).data.certificate
            this.certificateData.faqs = []
            this.certificateData.standard = []
            this.showStanderFile = decryptData(res.data.payload).data.certificate.standard
            this.showFAQsFile = decryptData(res.data.payload).data.certificate.faqs
            // console.log(this.certificateData)

            // this.certificateData.product_category_ids = decryptData(res.data.payload).data.certificate.product.map(item => ({
            //   value: item.id,
            //   text: item.name,
            // }))
            // console.log(this.certificateData)

            if (decryptData(res.data.payload).data.certificate.reports_files.length > 0) {
              this.addReport = true
              this.optionsReports = decryptData(res.data.payload).data.certificate.reports_files.map(item => ({
                name: item.report_name,
                file: item.file,
              }))
            } else {
              this.getReportName()
            }
          }).finally(() => {
            this.dataLoad = false
          })
      }
    },
    editCertificate() {
      this.loader = true
      // console.log(this.certificateData)
      const formData = new FormData()

      Object.keys(this.certificateData).forEach(key => {
        if (key === 'reports_files') {
          return
        }
        // if (key === 'product_category_ids' && Array.isArray(this.certificateData[key])) {
        //   this.certificateData[key].forEach((item, index) => {
        //     formData.append(`product_category_ids[${index}]`, item.value) // Append the value of each item
        //   })
        // } else
        if (Array.isArray(this.certificateData[key])) {
          this.certificateData[key].forEach((file, index) => {
            formData.append(`${key}[${index}]`, file)
          })
        } else if (typeof this.certificateData[key] === 'object' && this.certificateData[key] !== null) {
          formData.append(key, JSON.stringify(this.certificateData[key]))
        } else {
          formData.append(key, this.certificateData[key])
        }
      })

      // Append reports_files data
      this.optionsReports.forEach((report, index) => {
        if (report.file && !/^https?:\/\//.test(report.file)) {
          formData.append(`reports_files[${index}][report_name]`, report.name)
          formData.append(`reports_files[${index}][file]`, report.file)
        }
      })

      formData.append('_method', 'PUT')
      axios
        .post(
          `certificate/${this.$route.params.MCR_id}/${this.$route.params.id}`,
          formData,
        )
        .then(res => {
          this.$router.push({ name: 'certificate' })
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
            res.status === 200
              ? 'Updated Successfully'
              : 'Created Successfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        })
        .finally(() => {
          this.loader = false
        })
    },
    addCertificate() {
      this.loader = true
      const formData = new FormData()

      Object.keys(this.certificateData).forEach(key => {
        if (key === 'reports_files') {
          return
        }
        // if (key === 'product_category_ids' && Array.isArray(this.certificateData[key])) {
        //   this.certificateData[key].forEach((item, index) => {
        //     formData.append(`product_category_ids[${index}]`, item.value) // Append the value of each item
        //   })
        // } else
        if (Array.isArray(this.certificateData[key])) {
          this.certificateData[key].forEach((file, index) => {
            formData.append(`${key}[${index}]`, file)
          })
        } else if (typeof this.certificateData[key] === 'object' && this.certificateData[key] !== null) {
          formData.append(key, JSON.stringify(this.certificateData[key]))
        } else {
          formData.append(key, this.certificateData[key])
        }
      })
      formData.append('certificate_group_id', this.$route.params.MCR_id)
      axios
        .post(`certificate/${this.$route.params.MCR_id}`, formData)
        .then(res => {
          this.$router.push({ name: 'certificate' })
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                res.status === 200
                  ? 'Updated Successfully'
                  : 'Created Successfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        })
        .finally(() => {
          this.loader = false
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.file-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.file-chips {
  display: flex;
  position: absolute;
  width: fit-content;
  background-color: white;
  max-width: 92%;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 1;
}

.file-chips::-webkit-scrollbar {
  height: 4px; /* Adjust height for the scrollbar */
}

.file-chips::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

.file-chips::-webkit-scrollbar-thumb {
  background: #3624dd; /* Color of the scrollbar handle */
  border-radius: 2px; /* Rounded corners for the scrollbar handle */
}

.file-chips::-webkit-scrollbar-thumb:hover {
  background: #4c3cd5; /* Color when hovering over the scrollbar handle */
}

.file-name {
  display: inline-block;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

// .file-tooltip {
//   display: none;
//   position: fixed;
//   bottom: 0px; /* Adjust this value to position the tooltip below the badge */
//   left: 0;
//   transform: translateX(-50%);
//   background: #333;
//   color: #fff;
//   padding: 5px 10px;
//   border-radius: 4px;
//   white-space: nowrap;
//   z-index: 2;
// }

// .badge-item:hover .file-tooltip {
//   display: block;
// }
</style>
